window.addEventListener('load', function () {
    var form = document.querySelector('form');
    var successMessage = document.getElementById('successMessage');
    var errorMessage = document.getElementById('errorMessage');
    var fields = {};
    form.querySelectorAll('[name]').forEach(function (field) {
        fields[field.name] = field;
    });

    // Displays all error messages and adds 'error' classes to the form fields with
    // failed validation.
    var handleError = function (response) {
        var errors = [];
        for (var key in response) {
            if (!response.hasOwnProperty(key)) continue;
            if (fields.hasOwnProperty(key)) fields[key].classList.add('error');
            Array.prototype.push.apply(errors, response[key]);
        }
        // errorMessage.innerHTML = errors.join('<br>');
        errorMessage.innerHTML = errors[0];
    }

    var onload = function (e) {
        if (e.target.status === 200) {
            var firstName = document.getElementById('firstname').value;
            var company = document.getElementById('company').value;

            successMessage.innerHTML = 'Hej <span class="font-semibold">{{ firstname }}</span>, tak for interessen!<br>Din ansøgning er nu sendt og du vil høre nærmere fra mig omkring online butik for {{ company }}.<br><br>Med venligste hilsen Jimmy Rittenborg.'.replace('{{ firstname }}', firstName).replace('{{ company }}', company);

            var event = new Event("open-success-modal");
            window.dispatchEvent(event);
            // $dispatch('open-success-modal');

            for (var key in fields) {
                if (!fields.hasOwnProperty(key)) continue;
                fields[key].value = '';
            }

        } else {

            var event = new Event("open-error-modal");
            window.dispatchEvent(event);

            handleError(JSON.parse(e.target.response));
        }
    };

    var submit = function (e) {
        e.preventDefault();
        var request = new XMLHttpRequest();
        var formData = new FormData(e.target);
        // console.log(formData);
        request.open('POST', e.target.action);
        request.onload = onload;
        request.send(formData);
        // Remove all 'error' classes of a possible previously failed validation.
        for (var key in fields) {
            if (!fields.hasOwnProperty(key)) continue;
            fields[key].classList.remove('error');
        }
    };
    form.addEventListener('submit', submit);
});
